import * as React from 'react'
import Layout from '../componenets/layout'
import StationInformation from '../componenets/StationInformation'
import * as containerStyles from "../componenets/layout.module.css";

function Back() {
  return (
      // <div
      //   className={containerStyles.back}
      // >
      // <div className="text-center p-1 max-w-sm mx-auto bg-red-800 hover:bg-red-700 rounded-r shadow-md flex items-center text-sm md:text-base">
      //   <a href="/">
      //   <div>استكشف المحطات</div>
      //     <div>Explore stations</div>
      //   </a>
      // </div>
      // </div>
      <div
      className={containerStyles.back}
    >
      <a href="/" className="">
        <img src="/media/img/explore_network.svg" alt="explore network" className="h-20 md:h-32 lg:w-40 border-t border-r border-l border-white" />
      </a>
    </div>
  );
}

const IndexPage = () => {
  return (
    <Layout pageTitle="Station Information - Qatar Rail" imgsrc='/media/img/station_information_header.svg'>
      <StationInformation/>
      <Back/>
    </Layout>
  )
}
export default IndexPage