import React, { useEffect } from "react";
import stationData from "../data/stationData";
import { useLocation } from "@gatsbyjs/reach-router";
// import servicehours from "../images/servicehours.png";
// import metrolink from "../images/metrolink.png";
// import points_of_interest from "../images/points-of-interest.png";
// import retails from "../images/retails.png";
// import stationsImage from "../images/stations.png";
// import infoImage from "../images/covid.png";
import { Helmet } from "react-helmet";
import line_red from "../images/line_red.svg";
import line_green from "../images/line_green.svg";
import line_gold from "../images/line_gold.svg";
import line_orange from "../images/line_orange.svg";
import line_pink from "../images/line_pink.svg";
import line_turquoise from "../images/line_turquoise.svg";
import line_purple from "../images/line_purple.svg";
// import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import "./StationInformation.module.css";
import * as containerStyles from "./stationContainer.module.css"



const lineImages = {
  Red: line_red,
  Green: line_green,
  Gold: line_gold,
  Orange: line_orange,
  Pink: line_pink,
  Turquoise :line_turquoise,
  Purple: line_purple,
};

function getLineImage(line) {
  return lineImages[line];
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function StationInformationLinks({ station }) {
  // station.station_network.toLowerCase()

  // const images = useStaticQuery(graphql`
  // {
  //   events: file(relativePath: { eq: "events_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  //   servicehours: file(relativePath: { eq: "service_hours_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  //   metrolink: file(relativePath: { eq: "metrolink_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  //   points_of_interest: file(relativePath: { eq: "points_of_interest_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  //   retails: file(relativePath: { eq: "retail_shops_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  //   stations: file(relativePath: { eq: "other_stations_metro.png" }) {
  //     childImageSharp {
  //       gatsbyImageData(layout: CONSTRAINED)
  //     }
  //   }
  // }
  // `)
  let images = {};
  if (station.station_network === "Metro") {
    images.events = "/media/img/events_metro.svg";
    images.servicehours = "/media/img/service_hours_metro.svg";
    images.metrolink = "/media/img/metrolink_metro.svg";
    images.metroexpress = "/media/img/metroexpress_metro.svg";
    images.points_of_interest = "/media/img/points_of_interest_metro.svg";
    images.retails = "/media/img/retail_shops_metro.svg";
    images.stations = "/media/img/other_stations_metro.svg";
    images.plan_my_journey = "/media/img/plan_my_journey_metro.svg";
  } else {
    images.events = "/media/img/events_tram.svg";
    images.servicehours = "/media/img/service_hours_tram.svg";
    images.metrolink = "/media/img/metrolink_tram.svg";
    images.metroexpress = "/media/img/metroexpress_tram.svg";
    images.points_of_interest = "/media/img/points_of_interest_tram.svg";
    images.retails = "/media/img/retail_shops_tram.svg";
    images.stations = "/media/img/other_stations_tram.svg";
    images.plan_my_journey = "/media/img/plan_my_journey_tram.svg";
  }

  let links = [
    // {
    //   link_name: "Your guide to the stadium",
    //   link_name_ar: "دليلك إلى الملاعب",
    //   link_img: images.events,
    //   link_href: '/events'
    // }
    // {
    //   link_name: "Plan My Journey",
    //   link_name_ar: "التخطيط لرحلتي",
    //   link_img: images.plan_my_journey,
    //   link_href: `https://www.qr.com.qa/qrgis/PlanMyJourney/`,
    // },
  ];

  if (station.station_service_hours) {
    links.push({
      link_name: "Service Hours",
      // link_name_en: "Service Hours",
      // link_name_ar: "اوقات العمل",
      link_name_en: "Opening Hours",
      link_name_ar: "اوقات العمل",
      link_img: images.servicehours,
      link_href: `/media/service-hours/service_hours_all_stations.pdf`,
      // link_href: `/media/service-hours/service_hours_${station.station_name}.pdf`,
    });
  }

  if (station.station_metrolink) {
    links.push({
      link_name: "metrolink",
      link_name_en: <div><span className="font-semibold">metro</span>link</div>,
      link_name_ar: "مترولينك",
      link_img: images.metrolink,
      link_href: `/media/metrolink/metrolink_${station.station_name}.pdf`,
    });
  }

  if (station.station_metroexpress) {
    links.push({
      link_name: "metroexpress",
      link_name_en: <div><span className="font-semibold">metro</span>express</div>,
      link_name_ar: "مترو اكسبرس",
      link_img: images.metroexpress,
      link_href: `/media/metroexpress/metroexpress_map.pdf`,
    });
  }

  if (station.station_poi) {
    links.push({
      link_name: "Points of Interest",
      link_name_en: "Points of Interest",
      link_name_ar: "نقاط الاهتمام",
      link_img: images.points_of_interest,
      link_href: `/media/points-of-interest/points_of_interest_${station.station_name}.pdf`,
    });
  }

  if (station.station_retail_info) {
    links.push({
      link_name: "Retail",
      link_name_en: "Retail",
      link_name_ar: "المحلات التجارية",
      link_img: images.retails,
      link_href: `/media/retails/retails_${station.station_name}.pdf`,
    });
  }
  // links.push({
  //   link_name: "Explore stations", //"Sations / Maps",
  //   link_name_ar: "استكشف محطاتنا", //"المحطات والخرائط",
  //   link_img: images.stations,
  //   link_href: `/`,
  // });

  return (
    <div className="mb-2 flex justify-center rounded-lg md:pb-20 ">
      <div className="grid grid-cols-4 gap-2 py-2 md:grid-cols-4 text-gray-800 text-center px-5">
        {links.map((l) => (
          // <a href={l.link_href} key={l.link_name} className="flex flex-col ring-2 ring-gray-500 rounded-lg border border-gray-700 border-opacity-50 flex items-center text-center shadow">
          //   <GatsbyImage className="h-full object-cover h-2/12 w-full	rounded-t-lg"
          //     image={l.link_img.childImageSharp.gatsbyImageData}
          //     alt={l.link_name}
          //     title={l.link_name}
          //     width="640" height="360"
          //   />
          // <span className={`h-full h-2/12 rounded-b-lg bg-white p-2 md:text-2xl inline-block w-full ${l.link_name.length > 18 ? 'text-xs' : 'text-sm'}`}>
          //     <div>{l.link_name_ar}</div> <div>{l.link_name}</div>
          //   </span>
          //   </a>
          // <a href={l.link_href} key={l.link_name} className="max-w-sm rounded overflow-hidden shadow-lg ring-2 ring-gray-500 rounded-lg border border-gray-700 border-opacity-50 shadow">
          // <GatsbyImage className="w-full"
          //   image={l.link_img.childImageSharp.gatsbyImageData}
          //   alt={l.link_name}
          //   title={l.link_name}
          //   width="640" height="360"
          // />
          // <span className={`rounded-b-lg bg-white inline-block w-full h-14 md:h-24 md:pt-3 pt-1 ${l.link_name.length > 18 ? 'text-xs md:text-lg lg:text-2xl' : 'text-sm md:text-2xl'}`}>
          //   <div>{l.link_name_ar}</div> <div>{l.link_name}</div>
          // </span>
          // </a>
          <a
            href={l.link_href}
            key={l.link_name}
            className="flex flex-col rounded-lg bg-white md:m-1 overflow-hidden shadow-lg border-2 border-white"
          >
            <img
              src={l.link_img}
              alt={l.link_name}
              title={l.link_name}
              width="640"
              height="360"
              className="object-cover h-14 md:h-auto rounded"
            />
            <span
              className={`object-fill rounded-b-lg bg-white inline-block w-full h-10 md:h-24 md:py-2 pt-1 text-xs md:text-lg lg:text-2xl`}
            >
              <div className={l.link_name_ar.length > 12 ? "text-xxs md:text-lg lg:text-2xl" : ""}>{l.link_name_ar}</div> <div className={l.link_name_en.length > 12 ? "text-xxs md:text-lg lg:text-2xl" : ""}>{l.link_name_en}</div> 
            </span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default function StationInformation() {
  let query = useQuery();
  let sname = query?.get("name");
  let station = stationData.find((s) => s.station_name === sname);
  // let shelter_ops_img;
  // if (station?.station_shelter_ops){
  //   shelter_ops_img = (
  //     <div className={containerStyles.shelter_ops_img}>
  //     {station.station_shelter_ops ? 
  //     <img className="md:w-1/2 container mx-auto"
  //     alt={`${station.station_name_en} shelter operation`} src={`/media/shelter-ops/shelter_${station.station_name}.png`} ></img>
  //     : null}
  //    </div>
  //   )
  // }
  return (
    <div>
      {station ? (
        <div>
          <Helmet>
            <title>
              {`معلومات المحطة - ${station.station_name_ar} Station Information - ${station.station_name_en}`}
            </title>
          </Helmet>
          <div className="flex flex-col text-center md:p-5 text-2xl pb-2">
            {/* <div className="grid grid-cols-2 text-xl py-2">
              <div>
                <div className="md:text-4xl font-medium md:mb-4 pb-2">
                  Station Information
                </div>
              </div>
              <div>
                <div className="md:text-4xl font-medium md:mb-4 pb-2">
                  معلومات المحطة
                </div>
              </div>
            </div> */}
            <div className={containerStyles.stationInfoContainer}>
              
              {/* <img className={containerStyles.lineIcon}
      width="640" height="360"
      src={station_image} alt={station_name}/> */}
              {station.station_line.map((station_line_name) => (
                <img
                  className={containerStyles.lineInfoIcon}
                  width="640"
                  height="360"
                  src={getLineImage(capitalizeFirstLetter(station_line_name))}
                  alt={`${station_line_name} line`}
                  key={station_line_name}
                />
              ))}
              <div className="flex-1 font-semibold">
                <p className={containerStyles.stationInfoName}>{station.station_name_ar}</p>
                <p className={containerStyles.stationInfoName + " text-qr-gold"}>{station.station_name_en}</p>
              </div>
            </div>
          </div>
          <StationInformationLinks station={station} />
          {/* {shelter_ops_img} */}
        </div>
      ) : (
        <Redirect />
      )}
    </div>
  );
}

function Redirect() {
  useEffect(() => {
    window.location.href = "/";
  }, []);

  return (
    <div className="flex flex-col text-center md:pt-20 text-2xl">
      Loading Station Information
    </div>
  );
}
